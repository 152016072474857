const supportedLanguages = [
  {
    key: "en",
    value: "ENG",
    img: ".././flags/EN.webp",
  },
  {
    key: "tr",
    value: "TUR",
    img: ".././flags/turkey.png",
  },
  {
    key: "es",
    value: "ESP",
    img: ".././flags/SP.webp",
  },
  {
    key: "pt",
    value: "PRT",
    img: ".././flags/portugal.png",
  },
  {
    key: "it",
    value: "ITA",
    img: ".././flags/ita.png",
  },
  {
    key: "de",
    value: "GER",
    img: ".././flags/ger.webp",
  },
  {
    key: "fr",
    value: "FRE",
    img: ".././flags/FR.webp",
  },
  {
    key: "el",
    value: "GRE",
    img: ".././flags/Gre.webp",
  },
  {
    key: "sr",
    value: "SRP",
    img: ".././flags/Srp.webp",
  },
  {
    key: "nl",
    value: "NL",
    img: ".././flags/DE.png",
  },
  {
    key: "id",
    value: "ID",
    img: ".././flags/Id.png",
  },
  {
    key: "ar",
    value: "AR",
    img: ".././flags/ar.png",
  },
  {
    key: "no",
    value: "NO",
    img: ".././flags/no.png",
  },
  {
    key: "ru",
    value: "RU",
    img: ".././flags/ru.png",
  },
  {
    key: "da",
    value: "Da",
    img: ".././flags/da.png",
  },
  {
    key: "sv",
    value: "SWE",
    img: ".././flags/sv.png",
  },
  {
    key: "sq",
    value: "ALB",
    img: ".././flags/sq.png",
  },
];

export default supportedLanguages;
