import React from "react";
import { BrowserRouter } from "react-router-dom";
import HeadManager from "components/head_manager";
import ScrollManager from "components/scroll_manager";
import PWAPrompt from 'react-ios-pwa-prompt'

import { DataContextProvider } from "containers/data_context";

import Routing from "routing";


function App() {
  return (
    <BrowserRouter>
      <DataContextProvider>
        <HeadManager />
        <ScrollManager />
        <Routing />
        {/* <Reviews /> */}
        <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/>
      </DataContextProvider>
    </BrowserRouter>
  );
}

export default App;
