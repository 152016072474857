import { useCallback, useMemo, useReducer } from "react";
import {actions, reducer , INITIAL_THEME_STATE } from "./reducers/theme_data";

export default () => {
  const [themeData, dispatch] = useReducer(reducer, INITIAL_THEME_STATE);
  
  const loadThemeData = useCallback((channelId) => {
    return actions.loadThemeData(dispatch, channelId);
  }, []);
 

  const themeActions = useMemo(
    () => ({
      loadThemeData,
    }),
    [loadThemeData]
  );

  return useMemo(() => ({ themeData, themeActions }), [themeData, themeActions]);
};
