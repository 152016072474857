import { SET_THEME_LOADING, SET_THEME_DATA } from "./theme_actions";

const EMPTY_THEME = {
  data:null
};

export const INITIAL_THEME_STATE = {
  theme: EMPTY_THEME,
  isLoading: true,
};

const themeActionHandlers = {
  [SET_THEME_LOADING]: (state) => {
    return { ...state, isLoading: true };
  },
  [SET_THEME_DATA]: (state, action) => {
    return { ...state, theme: action.payload, isLoading: false };
  },
};

export const reducer = (state, action) => {
  const handler = themeActionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
