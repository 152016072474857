import { useCallback, useMemo, useReducer } from "react";

import { actions, INITIAL_STATE, reducer } from "./reducers/booking_data";
import {
  clearSavedState,
  getSavedState,
  setSavedState,
} from "./reducers/booking_data/utils/manage_saved_state";

export default () => {
  const [bookingData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setParams = useCallback((newParams) => {
    return actions.setParams(dispatch, newParams);
  }, []);
  const setPromotion = useCallback((promotionInfo) => {
    return actions.setPromotion(dispatch, promotionInfo);
  }, []);
  const setEvents = useCallback((eventsInfo) => {
    return actions.setEvents(dispatch, eventsInfo);
  }, []);
  const setSisterProperties = useCallback((sisterProperties) => {
    return actions.setSisterProperties(dispatch, sisterProperties);
  }, []);

  const resetParams = useCallback(() => {
    return actions.resetParams(dispatch);
  }, []);

  const setParamsAndLoadRoomsInfo = useCallback((channelId, newParams) => {
    return actions.setParamsAndLoadRoomsInfo(dispatch, channelId, newParams);
  }, []);

  const loadProperty = useCallback((channelId) => {
    return actions.loadProperty(dispatch, channelId);
  }, []);

  const loadRoomsInfo = useCallback((channelId, params) => {
    return actions.loadRoomsInfo(dispatch, channelId, params);
  }, []);
  const loadFilterRoomsInfo = useCallback((channelId, params) => {
    return actions.loadFilterRoomsInfo(dispatch, channelId, params);
  }, []);
  const loadClosedDates = useCallback((channelId) => {
    return actions.loadClosedDates(dispatch, channelId);
  }, []);
  const getPromotions = useCallback((channelId, roomsData, params) => {
    return actions.getPromotions(dispatch, channelId, roomsData, params);
  }, []);
  const getEvents = useCallback((channelId) => {
    return actions.getEvents(dispatch, channelId);
  }, []);
  const getNotifications = useCallback((channelId) => {
    return actions.getNotifications(dispatch, channelId);
  }, []);
  const getSisterProperties = useCallback((channelId) => {
    return actions.getSisterProperties(dispatch, channelId);
  }, []);
  const initBookingData = useCallback((bookingQueryParams, savedBookingData) => {
    return actions.initBookingData(dispatch, bookingQueryParams, savedBookingData);
  }, []);

  const loadBestOffer = useCallback((channelId, params) => {
    return actions.loadBestOffer(dispatch, channelId, params);
  }, []);

  const saveDataToStorage = useCallback((bookingDataToSave) => {
    setSavedState(bookingDataToSave);
  }, []);

  const getDataFromStorage = useCallback(() => {
    return getSavedState();
  }, []);

  const clearDataFromStorage = useCallback(() => {
    clearSavedState();
  }, []);

  const bookingActions = useMemo(
    () => ({
      initBookingData,
      setParams,
      resetParams,
      setParamsAndLoadRoomsInfo,
      getPromotions,
      getEvents,
      getNotifications,
      getSisterProperties,
      loadProperty,
      loadFilterRoomsInfo,
      loadRoomsInfo,
      loadClosedDates,
      setPromotion,
      setEvents,
      setSisterProperties,
      loadBestOffer,

      saveDataToStorage,
      getDataFromStorage,
      clearDataFromStorage,
    }),
    [
      initBookingData,
      setParams,
      resetParams,
      setParamsAndLoadRoomsInfo,
      getPromotions,
      getEvents,
      getNotifications,
      getSisterProperties,
      loadProperty,
      loadRoomsInfo,
      loadFilterRoomsInfo,
      loadClosedDates,
      loadBestOffer,
      setPromotion,
      setEvents,
      setSisterProperties,
      saveDataToStorage,
      getDataFromStorage,
      clearDataFromStorage,
    ],
  );

  return useMemo(() => ({ bookingData, bookingActions }), [bookingData, bookingActions]);
};
