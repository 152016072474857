import ApiActions from "api_actions";

export const SET_THEME_LOADING = "SET_THEME_LOADING";
export const SET_THEME_DATA = "SET_THEME_DATA";

const setThemeLoading = (dispatch) => {
    return dispatch({ type: SET_THEME_LOADING });
};

const setThemeData = (dispatch, payload) => {
    return dispatch({ type: SET_THEME_DATA, payload });
};

const loadThemeData = async (dispatch, channelId) => {
    
    if (channelId == null) {
        setThemeLoading(dispatch);
    }
    else {
        try {
            const data = await ApiActions.getThemeData(channelId);
            setThemeData(dispatch, data);
        } catch (error) {
            setThemeLoading(dispatch);
            setThemeData(dispatch, null);
        }
    }
};

export const actions = {
    loadThemeData,
};
